import {
  CreateFulfilmentOrderRequest,
  createFulfilmentOrderRequestSchema,
} from 'api-schema/lib';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { isNotErrorResponse } from '../../../../apiClient';
import { SnackbarDefaults } from '../../../../constants/snackbarDefaults';
import { useApiClient } from '../../../../hooks/useApiClient';
import { transformZodErrorsToFormik } from '../../../../utils/forms/transformZodErrorsToFormik';
import { FulfilmentOrderView } from './FulfilmentOrder.view';

export type LineItemError = { barcode: string; quantity: string };

export const FulfilmentOrder = () => {
  const { enqueueSnackbar } = useSnackbar();
  const apiClient = useApiClient();

  const formik = useFormik<CreateFulfilmentOrderRequest>({
    initialValues: {
      customer: {
        name: 'J Doe',
        phone: '0401 234 567',
      },
      address: {
        line1: '123 Test St',
      },
      lineItems: [
        { barcode: 'test-barcode-123', quantity: 1 },
        { barcode: 'test-barcode-456', quantity: 3 },
      ],
      merchantId: 'test-merchant-123',
      shippingMethod: 'Standard',
      externalFulfilmentId: undefined,
      isTest: true,
      isFcFulfillable: true,
    },
    onSubmit: async (formData) => {
      try {
        const { data, status } = await apiClient.post('/fulfilment-order', {
          body: formData,
        });
        if (isNotErrorResponse(data, status)) {
          enqueueSnackbar(
            'Mock fulfilment sent successfully',
            SnackbarDefaults
          );
        } else {
          // TODO(SKUT-864):
          enqueueSnackbar(data.error, {
            ...SnackbarDefaults,
            variant: 'error',
          });
        }
      } catch (error) {
        // error displayed by apiClient
      }
    },
    validate: transformZodErrorsToFormik<CreateFulfilmentOrderRequest>(
      createFulfilmentOrderRequestSchema
    ),
  });

  const addLineItem = () => {
    formik.setValues({
      ...formik.values,
      lineItems: [...formik.values.lineItems, { barcode: '', quantity: 1 }],
    });
  };

  const removeLineItem = (index: number) => {
    const lineItems = [...formik.values.lineItems];
    lineItems.splice(index, 1);
    formik.setValues({ ...formik.values, lineItems });
  };

  const getLineItemError = (index: number) => {
    if (formik.errors.lineItems && formik.errors.lineItems.length > index) {
      const error = formik.errors.lineItems[index];
      if (error) {
        return error.valueOf() as LineItemError;
      }
    }
    return undefined;
  };

  return (
    <FulfilmentOrderView
      {...formik}
      getLineItemError={getLineItemError}
      removeLineItem={removeLineItem}
      addLineItem={addLineItem}
    />
  );
};
