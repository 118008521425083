import { PortCategoryChip } from '../../../../components/warehouse/PortCategoryChip';
import { Text } from '../../../../components/warehouse/Text';
import {
  PortCategoryEnumValueType,
  PORT_CATEGORY,
  PORT_CATEGORY_LABEL,
} from '../../../../constants/port';
import { Wrapper } from './CategoryPanel.elements';

export type CategoryPanelProps = {
  categories: Array<PortCategoryEnumValueType>;
  queuedSelectedCategories: Array<PortCategoryEnumValueType> | null;
};

const VARIANT_CHIP_MAP = {
  [PORT_CATEGORY.SAME_DAY]: 'sameday',
  [PORT_CATEGORY.STANDARD]: 'standard',
  [PORT_CATEGORY.PRIORITY]: 'priority',
} as const;

const TransitioningChip = ({
  categories,
}: {
  categories: Array<PortCategoryEnumValueType>;
}) => {
  if (categories.length === 2) {
    return (
      <PortCategoryChip variant="transitioning">
        <Text tag="span">
          Transitioning to{' '}
          <Text weight="medium" tag="span">
            {PORT_CATEGORY_LABEL[categories[0]]}
          </Text>{' '}
          and{' '}
          <Text weight="medium" tag="span">
            {PORT_CATEGORY_LABEL[categories[1]]}
          </Text>{' '}
          modes
        </Text>
      </PortCategoryChip>
    );
  }

  return (
    <PortCategoryChip variant="transitioning">
      <Text tag="span">
        Transitioning to{' '}
        <Text weight="medium" tag="span">
          {PORT_CATEGORY_LABEL[categories[0]]}
        </Text>{' '}
        mode
      </Text>
    </PortCategoryChip>
  );
};

export function CategoryPanel({
  categories,
  queuedSelectedCategories,
}: CategoryPanelProps) {
  if (queuedSelectedCategories?.length) {
    return <TransitioningChip categories={queuedSelectedCategories} />;
  }

  return (
    <Wrapper>
      {categories.map((category) => (
        <PortCategoryChip variant={VARIANT_CHIP_MAP[category]}>
          <Text tag="span">{PORT_CATEGORY_LABEL[category]} mode</Text>
        </PortCategoryChip>
      ))}
    </Wrapper>
  );
}
