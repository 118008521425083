"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFosQueryStringSchema = exports.fulfilmentOrderSummaryApiSchema = void 0;
const zod_http_schemas_1 = require("zod-http-schemas");
const fulfilmentOrderSummary_1 = require("../readModels/fulfilmentOrderSummary");
exports.fulfilmentOrderSummaryApiSchema = (0, zod_http_schemas_1.createHttpSchema)({
    'GET /fulfilment-order-summary/:fulfilmentOrderId': {
        responseBody: zod_http_schemas_1.z.union([
            fulfilmentOrderSummary_1.fulfilmentOrderSummarySchema,
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('NOT_FOUND'),
            }),
        ]),
    },
    'GET /fulfilment-order-summary': {
        responseBody: zod_http_schemas_1.z.union([
            zod_http_schemas_1.z.object({
                fulfilmentOrderSummaries: fulfilmentOrderSummary_1.fulfilmentOrderSummarySchema.array(),
                totalItems: zod_http_schemas_1.z.number().int(),
                hasNextPage: zod_http_schemas_1.z.boolean(),
                totalPages: zod_http_schemas_1.z.number().int(),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('INVALID_PARAMETERS'),
                errors: zod_http_schemas_1.z.record(zod_http_schemas_1.z.array(zod_http_schemas_1.z.string())),
            }),
        ]),
    },
});
exports.getFosQueryStringSchema = zod_http_schemas_1.z.object({
    warehouseId: fulfilmentOrderSummary_1.fulfilmentOrderSummarySchema.shape.warehouseId.optional(),
    status: zod_http_schemas_1.z.array(fulfilmentOrderSummary_1.fulfilmentOrderSummarySchema.shape.status).optional(),
    externalOrderReference: fulfilmentOrderSummary_1.fulfilmentOrderSummarySchema.shape.externalOrderReference.optional(),
    externalFulfilmentId: fulfilmentOrderSummary_1.fulfilmentOrderSummarySchema.shape.externalFulfilmentId.optional(),
    merchantId: fulfilmentOrderSummary_1.fulfilmentOrderSummarySchema.shape.merchantId.optional(),
    carrierCode: fulfilmentOrderSummary_1.fulfilmentOrderSummarySchema.shape.carrierCode.optional(),
    shippingMethod: fulfilmentOrderSummary_1.fulfilmentOrderSummarySchema.shape.shippingMethod.optional(),
    page: zod_http_schemas_1.z.coerce.number().optional(),
    pageSize: zod_http_schemas_1.z.coerce.number().max(100).optional(),
});
