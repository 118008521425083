"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.warehouseApiSchema = void 0;
const zod_http_schemas_1 = require("zod-http-schemas");
const taskGroups_1 = require("../commands/outcomes/taskGroups");
const http_1 = require("../http");
const model_1 = require("../model");
const pickingMode_1 = require("../model/pickingMode");
const packingStationsOverview_1 = require("../readModels/packingStationsOverview");
exports.warehouseApiSchema = (0, zod_http_schemas_1.createHttpSchema)({
    'GET /warehouses': {
        responseBody: http_1.WarehousesResponseBody,
    },
    'POST /warehouses': {
        requestBody: model_1.WarehouseInput,
        responseBody: zod_http_schemas_1.z.union([http_1.ErrorBody, model_1.warehouseSchema]),
    },
    'PUT /warehouses/:id': {
        requestBody: http_1.EditWarehouseRequestBody,
        responseBody: zod_http_schemas_1.z.union([http_1.ErrorBody, model_1.warehouseSchema]),
    },
    'GET /warehouses/:id/storage-units': {
        responseBody: http_1.StorageUnitsResponseBody,
    },
    'POST /warehouses/:id/storage-units': {
        requestBody: http_1.ModifyStorageUnitsRequestBody,
        responseBody: http_1.StorageUnitsResponseBody,
    },
    'GET /warehouses/:warehouseId/fulfilment-order-status-count': {
        responseBody: zod_http_schemas_1.z.union([
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('SUCCESS'),
                fulfilmentOrderStatusCount: http_1.fulfilmentOrderStatusCountSchema,
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('NO_ORDERS_FOUND_FOR_WAREHOUSE'),
                warehouseId: zod_http_schemas_1.z.string(),
            }),
        ]),
    },
    'GET /warehouses/:warehouseId/autostore-bin/:autostoreBinId': {
        responseBody: zod_http_schemas_1.z.union([
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('AUTOSTORE_BIN_ID_NOT_A_NUMBER'),
                invalidAutostoreBinId: zod_http_schemas_1.z.string(),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('AUTOSTORE_BIN_NOT_FOUND'),
                autostoreBinId: zod_http_schemas_1.z.number(),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('SUCCESS'),
                autostoreBin: model_1.autostoreBinSchema,
            }),
        ]),
    },
    'GET /warehouses/:warehouseId/retail-units/:retailUnitKey/autostore-bins': {
        responseBody: zod_http_schemas_1.z.object({
            outcome: zod_http_schemas_1.z.literal('SUCCESS'),
            autostoreBins: zod_http_schemas_1.z.array(model_1.autostoreBinWithRetailUnitsSchema).optional(),
        }),
    },
    'POST /warehouses/:id/storage-units/autostore-bins': {
        requestBody: http_1.AutostoreBinConfiguration,
        responseBody: zod_http_schemas_1.z.union([http_1.ErrorBody, http_1.StorageUnitCreationResult]),
    },
    'POST /warehouses/:id/storage-units/totes': {
        requestBody: http_1.AddTotesRequest,
        responseBody: zod_http_schemas_1.z.union([http_1.ErrorBody, http_1.StorageUnitCreationResult]),
    },
    'POST /warehouses/:id/devices/autostore': {
        requestBody: http_1.AutostoreIntegrationRequest,
        responseBody: http_1.AutostoreIntegrationResponse,
    },
    'GET /warehouses/:id/transfers': {
        responseBody: zod_http_schemas_1.z.object({
            transfers: zod_http_schemas_1.z.array(model_1.transferSchema),
        }),
    },
    'GET /warehouses/:id/transfers/:transferId': {
        responseBody: model_1.transferSchema,
    },
    'POST /warehouses/:id/transfers': {
        requestBody: http_1.createTransferRequest,
        responseBody: http_1.createTransferResponse,
    },
    'POST /warehouses/:id/transfers/:transferId/scanned': {
        requestBody: zod_http_schemas_1.z.object({
            portId: zod_http_schemas_1.z.string(),
        }),
        responseBody: zod_http_schemas_1.z.union([http_1.ErrorBody, model_1.transferSchema]),
    },
    'POST /warehouses/:warehouseId/port/:portId/open': {
        requestBody: zod_http_schemas_1.z.object({
            contentCodes: zod_http_schemas_1.z.array(zod_http_schemas_1.z.number()),
        }),
        responseBody: zod_http_schemas_1.z.union([
            http_1.ErrorBody,
            zod_http_schemas_1.z.object({
                binId: zod_http_schemas_1.z.number(),
                binRetrievalDurationMs: zod_http_schemas_1.z.number(),
            }),
        ]),
    },
    'GET /warehouses/:id/ports': {
        responseBody: http_1.PortsResponseBody,
    },
    'GET /warehouses/:id/ports-hydrated': {
        responseBody: zod_http_schemas_1.z.union([
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('PORTS_NOT_FOUND'),
            }),
            http_1.HydratedPortsResponseBody,
        ]),
    },
    'GET /warehouses/:id/port-state/:portId': {
        responseBody: http_1.PortStateResponseBody,
    },
    'POST /warehouses/:id/ports/add': {
        requestBody: zod_http_schemas_1.z.object({ portId: zod_http_schemas_1.z.number() }),
        responseBody: zod_http_schemas_1.z.union([http_1.ErrorBody, zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.string() })]),
    },
    'PUT /warehouses/:id/ports/:portId': {
        requestBody: zod_http_schemas_1.z.object({
            type: model_1.portTypeSchema,
        }),
        responseBody: zod_http_schemas_1.z.union([http_1.ErrorBody, http_1.PortsResponseBody]),
    },
    'PUT /warehouses/:warehouseId/ports/:portId/conveyor': {
        requestBody: zod_http_schemas_1.z.object({ shouldUseConveyorSystem: zod_http_schemas_1.z.boolean() }),
        responseBody: zod_http_schemas_1.z.union([
            http_1.ErrorBody,
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('SUCCESS'),
                portId: zod_http_schemas_1.z.number(),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('INVALID_PORT_ID'),
                portId: zod_http_schemas_1.z.string(),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('PICK_PORT_NOT_FOUND'),
                portId: zod_http_schemas_1.z.number(),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('INVALID_PORT_STATUS'),
                portId: zod_http_schemas_1.z.number(),
                expectedStatus: zod_http_schemas_1.z.literal('CLOSED'),
                actualStatus: model_1.PickPortStatus,
            }),
        ]),
    },
    'POST /warehouses/:warehouseId/cmc-genesys/:machineId/install': {
        responseBody: zod_http_schemas_1.z.union([
            http_1.ErrorBody,
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.union([
                    zod_http_schemas_1.z.literal('WAREHOUSE_NOT_FOUND'),
                    zod_http_schemas_1.z.literal('SUCCESS'),
                    zod_http_schemas_1.z.literal('CMC_GENESYS_MACHINE_ALREADY_EXISTS'),
                ]),
            }),
        ]),
    },
    'POST /warehouses/:warehouseId/cmc-genesys/:machineId/service-code/add': {
        requestBody: zod_http_schemas_1.z.object({ serviceCode: zod_http_schemas_1.z.string().min(1) }),
        responseBody: zod_http_schemas_1.z.union([
            http_1.ErrorBody,
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.union([
                    zod_http_schemas_1.z.literal('WAREHOUSE_NOT_FOUND'),
                    zod_http_schemas_1.z.literal('CMC_GENESYS_PACKAGING_CONFIG_NOT_FOUND'),
                    zod_http_schemas_1.z.literal('SERVICE_CODE_ALREADY_ELIGIBLE'),
                    zod_http_schemas_1.z.literal('SUCCESS'),
                ]),
            }),
        ]),
    },
    'POST /warehouses/:warehouseId/cmc-genesys/:machineId/service-code/remove': {
        requestBody: zod_http_schemas_1.z.object({ serviceCode: zod_http_schemas_1.z.string().min(1) }),
        responseBody: zod_http_schemas_1.z.union([
            http_1.ErrorBody,
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.union([
                    zod_http_schemas_1.z.literal('WAREHOUSE_NOT_FOUND'),
                    zod_http_schemas_1.z.literal('CMC_GENESYS_PACKAGING_CONFIG_NOT_FOUND'),
                    zod_http_schemas_1.z.literal('SERVICE_CODE_NOT_FOUND'),
                    zod_http_schemas_1.z.literal('SUCCESS'),
                ]),
            }),
        ]),
    },
    'POST /warehouses/:warehouseId/ports/:portId/select-categories': {
        requestBody: zod_http_schemas_1.z.object({
            categories: model_1.PickPortSelectCategories.nonempty({
                message: 'Please provide at least one valid category, an empty array is not allowed.',
            }),
        }),
        responseBody: zod_http_schemas_1.z.union([
            http_1.ErrorBody,
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.union([
                    zod_http_schemas_1.z.literal('SUCCESS'),
                    zod_http_schemas_1.z.literal('PORT_SELECT_CATEGORIES_UNCHANGED'),
                ]),
            }),
        ]),
    },
    'GET /warehouses/:warehouseId/task-groups-by-status': {
        responseBody: zod_http_schemas_1.z.union([
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('SUCCESS'),
                taskGroups: model_1.taskGroupSchema.array(),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('NO_TASK_GROUPS_FOUND'),
                statuses: model_1.taskGroupStatusSchema.array(),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('NO_STATUSES_SUPPLIED'),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('INVALID_STATUSES_SUPPLIED'),
            }),
        ]),
    },
    'POST /warehouses/task-groups/create': {
        responseBody: zod_http_schemas_1.z.union([http_1.ErrorBody, zod_http_schemas_1.z.object({ processing: zod_http_schemas_1.z.boolean() })]),
    },
    'POST /warehouses/task-groups/:warehouseId/create': {
        responseBody: zod_http_schemas_1.z.union([http_1.ErrorBody, zod_http_schemas_1.z.object({ processed: zod_http_schemas_1.z.boolean() })]),
    },
    'POST /warehouses/task-groups/assign': {
        responseBody: zod_http_schemas_1.z.union([http_1.ErrorBody, zod_http_schemas_1.z.object({ processing: zod_http_schemas_1.z.boolean() })]),
    },
    'POST /warehouses/task-groups/:warehouseId/assign': {
        responseBody: zod_http_schemas_1.z.union([http_1.ErrorBody, zod_http_schemas_1.z.object({ processed: zod_http_schemas_1.z.boolean() })]),
    },
    'POST /warehouses/:warehouseId/task-groups/generation': {
        requestBody: zod_http_schemas_1.z.object({
            picklistIds: zod_http_schemas_1.z.string().array().optional(),
            pickingModeName: pickingMode_1.pickingModeNameSchema.optional(),
        }),
        responseBody: zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.string() }),
    },
    'POST /warehouses/task-groups/sync': {
        responseBody: zod_http_schemas_1.z.union([http_1.ErrorBody, zod_http_schemas_1.z.object({ processing: zod_http_schemas_1.z.boolean() })]),
    },
    'POST /warehouses/task-groups/:warehouseId/sync': {
        responseBody: zod_http_schemas_1.z.union([http_1.ErrorBody, zod_http_schemas_1.z.object({ processed: zod_http_schemas_1.z.boolean() })]),
    },
    'POST /warehouses/task-groups/:warehouseId/validate': {
        responseBody: zod_http_schemas_1.z.union([
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('TASK_GROUPS_WITH_INVALID_PICKLIST_STATUS'),
                taskGroupIds: zod_http_schemas_1.z.string().array(),
            }),
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('SUCCESS') }),
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('WAREHOUSE_NOT_FOUND') }),
        ]),
    },
    'POST /task-group/:taskGroupId/cancel': {
        responseBody: zod_http_schemas_1.z.union([http_1.ErrorBody, zod_http_schemas_1.z.object({ processing: zod_http_schemas_1.z.boolean() })]),
    },
    'POST /task-groups/bulk-cancel': {
        requestBody: zod_http_schemas_1.z.object({
            taskGroupIds: zod_http_schemas_1.z.string().array(),
        }),
        responseBody: taskGroups_1.CancelTaskGroupByIdOutcomes.array(),
    },
    'POST /warehouses/autostore-state/sync': {
        responseBody: zod_http_schemas_1.z.union([http_1.ErrorBody, zod_http_schemas_1.z.object({ processing: zod_http_schemas_1.z.boolean() })]),
    },
    'GET /warehouses/:warehouseId/tote/:toteId/order': {
        responseBody: http_1.ToteOrderResponseBody,
    },
    'POST /warehouses/:warehouseId/autostore-bin/:autostoreBinId/:partitionNumber/quantity': {
        requestBody: zod_http_schemas_1.z.object({
            merchantId: zod_http_schemas_1.z.string().min(1),
            barcode: zod_http_schemas_1.z.string().min(1),
            quantity: zod_http_schemas_1.z.number().nonnegative(),
        }),
        responseBody: zod_http_schemas_1.z.union([
            http_1.ErrorBody,
            zod_http_schemas_1.z.object({
                success: zod_http_schemas_1.z.boolean(),
                previousQuantity: zod_http_schemas_1.z.number().nonnegative(),
                updatedQuantity: zod_http_schemas_1.z.number().nonnegative(),
            }),
        ]),
    },
    'POST /warehouses/:warehouseId/autostore-bin/:autostoreBinId/:partitionNumber/hand-pick-allocated': {
        requestBody: zod_http_schemas_1.z.object({
            merchantId: zod_http_schemas_1.z.string().min(1),
            barcode: zod_http_schemas_1.z.string().min(1),
            quantity: zod_http_schemas_1.z.number().nonnegative(),
            partitionIsEmpty: zod_http_schemas_1.z.boolean().optional(),
        }),
        responseBody: zod_http_schemas_1.z.union([
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('SUCCESS'),
                previousQuantity: zod_http_schemas_1.z.number(),
                updatedQuantity: zod_http_schemas_1.z.number(),
                binId: zod_http_schemas_1.z.string(),
                binIsEmpty: zod_http_schemas_1.z.boolean(),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('FAILED_TO_PARSE_PARAMS'),
                errors: zod_http_schemas_1.z.record(zod_http_schemas_1.z.string().array()),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('BIN_NOT_FOUND'),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('INVALID_STORAGE_UNIT_TYPE'),
                expectedType: zod_http_schemas_1.z.literal('AUTOSTORE_BIN'),
                actualType: model_1.StorageType,
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('PARTITION_NOT_FOUND'),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('PARTITION_DOES_NOT_CONTAIN_RETAILUNIT_KEY'),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('INVALID_RETAILUNIT_KEY_FORMAT'),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('PARTITION_DOES_NOT_CONTAIN_REQUESTED_BARCODE'),
                barcodeInPartition: zod_http_schemas_1.z.string().optional(),
                requestedBarcode: zod_http_schemas_1.z.string(),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('COULDNT_UPDATE_BIN_CONTENT_CODE'),
                autostoreBinId: zod_http_schemas_1.z.number(),
                autoStoreErrorCode: zod_http_schemas_1.z.number().optional(),
            }),
        ]),
    },
    'GET /warehouses/:warehouseId/packing/stations-overview': {
        responseBody: zod_http_schemas_1.z.union([
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('NO_PACKING_STATIONS_FOUND_FOR_WAREHOUSE'),
                warehouseId: zod_http_schemas_1.z.string(),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('SUCCESS'),
                packingStations: zod_http_schemas_1.z.array(packingStationsOverview_1.packingStationOverviewSchema),
            }),
        ]),
    },
    'POST /warehouses/:warehouseId/ports/:portId/check-bin-has-arrived': {
        responseBody: zod_http_schemas_1.z.union([
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('PORT_NOT_FOUND'),
                warehouseId: zod_http_schemas_1.z.string(),
                portId: zod_http_schemas_1.z.number(),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('INVALID_PORT_TYPE'),
                expectedPortType: zod_http_schemas_1.z.literal('PICK'),
                actualPortType: model_1.portTypeSchema,
                warehouseId: zod_http_schemas_1.z.string(),
                portId: zod_http_schemas_1.z.number(),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('INVALID_PORT_STATUS'),
                currentPortStatus: model_1.PickPortStatus,
                expectedPortStatus: zod_http_schemas_1.z.literal('AWAITING_BIN'),
                warehouseId: zod_http_schemas_1.z.string(),
                portId: zod_http_schemas_1.z.number(),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('NO_CURRENT_BIN'),
                warehouseId: zod_http_schemas_1.z.string(),
                portId: zod_http_schemas_1.z.number(),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('COULD_NOT_GET_AUTOSTORE_PORT_STATUS'),
                portId: zod_http_schemas_1.z.number(),
                autostoreError: zod_http_schemas_1.z.string(),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('AUTOSTORE_BIN_AT_PORT_MISMATCH'),
                autostorePortBinId: zod_http_schemas_1.z.number().optional(),
                wmsPortBinId: zod_http_schemas_1.z.number(),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('AUTOSTORE_PORT_TASK_ID_MISMATCH'),
                autostorePortTaskId: zod_http_schemas_1.z.number().optional(),
                wmsNextPortTaskId: zod_http_schemas_1.z.number().optional(),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('AUTOSTORE_BIN_AT_PORT_NOT_READY'),
                autostorePortBinId: zod_http_schemas_1.z.number().optional(),
            }),
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('SUCCESS') }),
        ]),
    },
    'POST /warehouses/:warehouseId/ports/:portId/unlock-pick-port': {
        responseBody: zod_http_schemas_1.z.discriminatedUnion('outcome', [
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('SUCCESS') }),
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('PORT_NOT_FOUND') }),
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('PORT_NOT_LOCKED') }),
        ]),
    },
    'POST /warehouses/:warehouseId/ports/:portId/lock-pick-port': {
        responseBody: zod_http_schemas_1.z.discriminatedUnion('outcome', [
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('SUCCESS') }),
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('PORT_NOT_FOUND') }),
            zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('PORT_ALREADY_LOCKED') }),
        ]),
    },
    'POST /warehouses/:warehouseId/configure-cmc-genesys-routing': {
        responseBody: zod_http_schemas_1.z.union([
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('SUCCESS'),
                percentage: zod_http_schemas_1.z.number(),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('WAREHOUSE_NOT_FOUND'),
                warehouseId: zod_http_schemas_1.z.string(),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('ROUTING_PERCENTAGE_INVALID'),
                minPercentage: zod_http_schemas_1.z.number(),
                maxPercentage: zod_http_schemas_1.z.number(),
                receivedPercentage: zod_http_schemas_1.z.number(),
            }),
        ]),
        requestBody: zod_http_schemas_1.z.object({
            percentageForCmcGenesys: zod_http_schemas_1.z.number(),
        }),
    },
    'GET /warehouses/:warehouseId/cmc-genesys-configuration': {
        responseBody: zod_http_schemas_1.z.union([
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('WAREHOUSE_NOT_FOUND'),
            }),
            zod_http_schemas_1.z.object({
                outcome: zod_http_schemas_1.z.literal('SUCCESS'),
                cmcGenesysRoutingPercentage: zod_http_schemas_1.z.number(),
                cmcGenesysPackaging: zod_http_schemas_1.z.record(model_1.cmcGenesysPackagingSchema),
            }),
        ]),
    },
});
