"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageUnit = exports.autostoreBinWithRetailUnitsSchema = exports.autostoreBinSchema = exports.partitionWithRetailUnitSchema = exports.partitionSchema = exports.toteWithRetailUnitsSchema = exports.toteSchema = exports.toteContentsSchema = exports.StorageStatus = exports.StorageType = void 0;
const z = __importStar(require("zod"));
const autostore_1 = require("./autostore");
const retailUnit_1 = require("./retailUnit");
const date_1 = require("../events/date");
exports.StorageType = z.enum(['AUTOSTORE_BIN', 'TOTE']);
exports.StorageStatus = z.enum(['ACTIVE', 'DISABLED']);
exports.toteContentsSchema = z.object({
    /** @deprecated retailUnit will be removed here. Use ToteWithRetailUnits instead */
    retailUnit: retailUnit_1.retailUnitSchema,
    retailUnitKey: z.string().optional(),
    quantity: z.number().int().nonnegative(),
});
exports.toteSchema = z.object({
    id: z.string().min(1),
    contents: z.array(exports.toteContentsSchema),
    status: exports.StorageStatus,
    storageType: z.literal('TOTE'),
    picklistId: z.string().optional(),
    fulfilmentOrderId: z.string().optional(),
    packingStationId: z.number().optional(),
    warehouseId: z.string(),
});
exports.toteWithRetailUnitsSchema = exports.toteSchema.extend({
    contents: z.array(exports.toteContentsSchema.extend({ retailUnit: retailUnit_1.retailUnitSchema.optional() })),
});
exports.partitionSchema = z.object({
    partitionNumber: z.number().int().positive(),
    quantity: z.number().int().nonnegative(),
    quantityAllocated: z.number().int().nonnegative(),
    retailUnitKey: z.string().optional(),
    createdAt: z.union([z.string(), z.date()]).optional(),
    warehouseId: z.string(),
    quantityPutaway: z.number().int().nonnegative().optional(),
});
exports.partitionWithRetailUnitSchema = exports.partitionSchema.extend({
    retailUnit: retailUnit_1.retailUnitSchema.optional(),
});
exports.autostoreBinSchema = z.object({
    id: z.string(),
    autostoreBinId: z.number().int().gte(10000),
    binType: autostore_1.AutostoreBinType,
    partitions: z.array(exports.partitionSchema),
    status: exports.StorageStatus,
    storageType: z.literal('AUTOSTORE_BIN'),
    lastPickedAt: date_1.dateSchema.optional(),
    emptyQuantityPutawayPartitionMap: z
        .record(z.union([z.number(), z.string()]), z.boolean())
        .optional(),
    warehouseId: z.string(),
    currentPortId: z.number().optional(),
});
exports.autostoreBinWithRetailUnitsSchema = exports.autostoreBinSchema.extend({
    partitions: z.array(exports.partitionWithRetailUnitSchema),
});
exports.StorageUnit = z.union([exports.autostoreBinSchema, exports.toteSchema]);
