"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.featureFlagApiSchema = void 0;
const zod_http_schemas_1 = require("zod-http-schemas");
const http_1 = require("../http");
exports.featureFlagApiSchema = (0, zod_http_schemas_1.createHttpSchema)({
    'GET /feature-flags-status': {
        responseBody: http_1.GetFeatureFlagsStatusResponse,
    },
    'POST /warehouses/:warehouseId/ports/:portId/reset-release-channel': {
        responseBody: http_1.ResetPortReleaseChannelResponse,
    },
    'POST /warehouses/:warehouseId/ports/:portId/assign-release-channel': {
        requestBody: http_1.AssignReleaseChannelToPortRequest,
        responseBody: http_1.AssignReleaseChannelToPortResponse,
    },
    'POST /warehouses/:warehouseId/packing-stations/:packingStationId/reset-release-channel': {
        responseBody: http_1.ResetPackingStationReleaseChannelResponse,
    },
    'POST /warehouses/:warehouseId/packing-stations/:packingStationId/assign-release-channel': {
        requestBody: http_1.AssignReleaseChannelToPackingStationRequest,
        responseBody: http_1.AssignReleaseChannelToPackingStationResponse,
    },
});
