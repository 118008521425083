export const colours = {
  primary: {
    main: '#4D6772',
    accent: '#728F9B',
    light: '#E7F1F5',
  },
  secondary: {
    main: '#4D6772',
    accent: '#728F9B',
    light: '#E7F1F5',
  },
  error: {
    light: '#FCE7E7',
    main: '#E04A47',
    dark: '#C62828',
  },
  warning: {
    dark: '#EFA933',
    main: '#F8D66F',
  },
  success: {
    dark: '#4D6772',
    main: '#A9D3AC',
  },
  info: {
    main: '#73C8EE',
  },
  background: {
    default: '#FBFCFD',
  },
  disabled: {
    main: '#ECECEC',
    contrastText: '#212121c4',
  },
  orangeYellow: { main: '#FFD266', contrastText: '#473814' },
  metallicBronze: { main: '#DAEAF0', contrastText: '#213E4B' },
};

export const warehouseColours = {
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  /** Blue */
  primary: {
    main: '#0051A8',
    light: '#B5D4F4',
    dark: '#002F61',
    contrastText: '#FFFFFF',
  },
  /** Navy */
  secondary: {
    main: '#3A4E59',
    dark: '#07151D',
    base: '#97A7B0',
    contrastText: '#FFFFFF',
  },
  /** Grey */
  tertiary: {
    main: '#E8E9ED',
    dark: '#64727D',
    contrastText: '#212121',
  },
  disabled: {
    main: 'rgba(33, 33, 33, 0.1)',
    secondary: 'rgba(33, 33, 33, 0.15)',
    contrastText: 'rgba(33, 33, 33, 0.65)',
  },
  grey: {
    1: '#FCFCFC',
    2: '#F3F3F3',
    3: '#E8E9ED',
    4: '#D9DBE1',
    5: '#8B909C',
    6: '#64727D',
  },
  /** Red */
  danger: {
    main: '#940005',
    dark: '#650005',
    base: '#CD0909',
    contrastText: '#FFFFFF',
  },
  error: {
    dark: '#650005',
    main: '#940005',
    base: '#CD0909',
    light: '#FFD8D8',
    contrastText: '#FFFFFF',
  },
  /** Yellow */
  warning: {
    light: '#FFEAB8',
    main: '#FFC845',
    dark: '#AD7C01',
    contrastText: '#212121',
  },
  success: {
    light: '#B5D4F4',
    main: '#0051A8',
    dark: '#002F61',
    contrastText: '#212121',
  },
  info: {
    light: '#DFF2F8',
    main: '#269DC5',
    base: '#00BCF0',
    dark: '#007EA7',
  },
  text: {
    primary: '#212121',
    secondary: '#767676',
    placeholder: 'rgba(33, 33, 33, 0.65)',
    info: '#212121c4',
  },
  background: {
    paper: '#FFFFFF',
    panel: '#FFFFFF',
    default: '#F3F3F3',
  },
  category: {
    background: {
      standard: 'rgba(139, 144, 156, 0.4)', // #8B909C with 40% opacity
      sameday: 'rgba(22, 125, 237, 0.4)', // #167DED with 40% opacity
      priority: 'rgba(244, 200, 86, 1)', // #F4C856
      transitioning: '#00BCF0',
    },
  },
  blue: {
    main: '#269DC5',
    light: '#DFF2F8',
    secondary: '#007EA7',
    transparent: 'rgba(0, 188, 240, 0.5)',
  },
};
