"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.markOrderAsPackedResponseSchema = exports.RequestLabelResponse = exports.fulfilmentOrderStatusCountSchema = exports.changeFulfilmentOrderTagsRequestSchema = exports.createFulfilmentOrderRequestSchema = exports.PostalAddressSchema = exports.OrderRequestLineItem = exports.CreateFulfilmentOrderResponse = void 0;
const zod_http_schemas_1 = require("zod-http-schemas");
const address_1 = require("../constants/address");
const base_1 = require("../events/base");
const fulfilmentOrder_1 = require("../model/fulfilmentOrder");
const warehouse_1 = require("./warehouse");
// This model won't really be used internally so I wonder if it actually belongs here
exports.CreateFulfilmentOrderResponse = zod_http_schemas_1.z.object({
    warehouseId: zod_http_schemas_1.z.string().min(1),
    fulfilmentOrderId: zod_http_schemas_1.z.string(),
});
exports.OrderRequestLineItem = zod_http_schemas_1.z.object({
    barcode: zod_http_schemas_1.z.string().nullish(),
    quantity: zod_http_schemas_1.z.number().int().nullish(),
    isBundle: zod_http_schemas_1.z.boolean().optional(),
    isDigital: zod_http_schemas_1.z.boolean().optional(),
    sku: zod_http_schemas_1.z.string().nullish(),
});
exports.PostalAddressSchema = zod_http_schemas_1.z.object({
    administrativeAreaLevel1: zod_http_schemas_1.z.string().nullish(),
    administrativeAreaLevel2: zod_http_schemas_1.z.string().nullish(),
    administrativeAreaLevel3: zod_http_schemas_1.z.string().nullish(),
    company: zod_http_schemas_1.z.string().nullish(),
    contactNumber: zod_http_schemas_1.z.string().nullish(),
    countryCode: address_1.countryCodeSchema.nullish(),
    countryName: zod_http_schemas_1.z.string().nullish(),
    line1: zod_http_schemas_1.z.string().nullish(),
    line2: zod_http_schemas_1.z.string().nullish(),
    locality: zod_http_schemas_1.z.string().nullish(),
    name: zod_http_schemas_1.z.string().nullish(),
    postalCode: zod_http_schemas_1.z.string().nullish(),
});
// Maybe this belongs somewhere else since it's only really used externally
exports.createFulfilmentOrderRequestSchema = zod_http_schemas_1.z.object({
    merchantId: zod_http_schemas_1.z.string().min(1),
    merchantName: zod_http_schemas_1.z.string().min(1).optional(),
    lineItems: zod_http_schemas_1.z.array(exports.OrderRequestLineItem),
    customer: zod_http_schemas_1.z.object({
        name: zod_http_schemas_1.z.string().min(1).optional(),
        phone: zod_http_schemas_1.z.string().min(1).optional(),
    }),
    address: zod_http_schemas_1.z.optional(exports.PostalAddressSchema),
    externalFulfilmentId: zod_http_schemas_1.z.string().min(1).optional(),
    externalOrderReference: zod_http_schemas_1.z.string().optional(),
    shippingMethod: fulfilmentOrder_1.shippingMethod,
    isTest: zod_http_schemas_1.z.boolean().optional(),
    isFcFulfillable: zod_http_schemas_1.z.boolean().optional(),
    tags: zod_http_schemas_1.z.array(zod_http_schemas_1.z.string()).optional(),
});
const updateFulfilmentShipmentSchema = zod_http_schemas_1.z.object({
    id: zod_http_schemas_1.z.string().min(1),
    status: zod_http_schemas_1.z.string().min(1),
    source: zod_http_schemas_1.z.enum(['SKUTOPIA', 'EXTERNAL']),
});
exports.changeFulfilmentOrderTagsRequestSchema = zod_http_schemas_1.z.object({
    tags: zod_http_schemas_1.z.array(zod_http_schemas_1.z.string()),
    integrations: zod_http_schemas_1.z
        .object({
        ssp: zod_http_schemas_1.z
            .object({
            salesOrderId: zod_http_schemas_1.z.string().optional(),
            externalFulfilmentOrderId: zod_http_schemas_1.z.string().optional(),
            orderReference: zod_http_schemas_1.z.string().nullish(),
            merchantId: zod_http_schemas_1.z.string().optional(),
            storeId: zod_http_schemas_1.z.string().optional(),
        })
            .optional(),
    })
        .optional(),
});
exports.fulfilmentOrderStatusCountSchema = zod_http_schemas_1.z.record(fulfilmentOrder_1.fulfilmentOrderStatusSchema, zod_http_schemas_1.z.number());
exports.RequestLabelResponse = zod_http_schemas_1.z.union([warehouse_1.ErrorBody, base_1.LabelPaths]);
exports.markOrderAsPackedResponseSchema = zod_http_schemas_1.z.union([
    zod_http_schemas_1.z.object({
        outcome: zod_http_schemas_1.z.literal('SUCCESS'),
    }),
    zod_http_schemas_1.z.object({
        outcome: zod_http_schemas_1.z.literal('WAREHOUSE_NOT_FOUND'),
    }),
    zod_http_schemas_1.z.object({
        outcome: zod_http_schemas_1.z.literal('TOTE_NOT_FOUND'),
    }),
    zod_http_schemas_1.z.object({
        outcome: zod_http_schemas_1.z.literal('ORDER_NOT_IN_TOTE'),
    }),
    zod_http_schemas_1.z.object({
        outcome: zod_http_schemas_1.z.literal('FULFILMENT_ORDER_NOT_FOUND'),
    }),
    zod_http_schemas_1.z.object({
        outcome: zod_http_schemas_1.z.literal('INVALID_FULFILMENT_ORDER_STATUS'),
    }),
    zod_http_schemas_1.z.object({
        outcome: zod_http_schemas_1.z.literal('INCOMPLETE_PICKLIST_FOUND'),
    }),
]);
