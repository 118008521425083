"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.finishCmcGenesysParcelOutcomeSchema = exports.removeCmcGenesysParcelOutcomeSchema = exports.labelCmcGenesysParcelOutcomeSchema = exports.exitCmcGenesysToteOutcomeSchema = exports.measureCmcGenesysParcelOutcomeSchema = exports.measureCmcGenesysParcelSuccessOutcomeSchema = exports.measureCmcGenesysParcelErrorOutcomeSchema = exports.scanCmcGenesysToteOutcomeSchema = exports.scanCmcGenesysToteSuccessOutcomeSchema = exports.scanCmcGenesysToteErrorOutcomeSchema = exports.orderAlreadyPackedSchema = exports.orderNotEligibleForCmcGenesysSchema = exports.validateCmcGenesysParcelErrorOutcomeSchema = exports.validateCmcGenesysToteErrorOutcomeSchema = exports.commandFailedOutcomeSchema = exports.isTestSchema = void 0;
const zod_1 = require("zod");
const http_1 = require("../../http");
const model_1 = require("../../model");
exports.isTestSchema = zod_1.z.object({
    isTest: zod_1.z.boolean(),
});
exports.commandFailedOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('COMMAND_FAILED'),
});
exports.validateCmcGenesysToteErrorOutcomeSchema = zod_1.z
    .union([
    zod_1.z.object({
        reason: zod_1.z.literal('NO_WAREHOUSE_FOUND'),
        warehouseId: zod_1.z.string(),
        toteId: zod_1.z.string(),
    }),
    zod_1.z.object({
        reason: zod_1.z.literal('STORAGE_UNIT_NOT_FOUND'),
        toteId: zod_1.z.string(),
    }),
    zod_1.z.object({
        reason: zod_1.z.literal('INVALID_STORAGE_TYPE'),
        toteId: zod_1.z.string(),
        expected: zod_1.z.string(),
        received: zod_1.z.string(),
    }),
    zod_1.z.object({
        reason: zod_1.z.literal('NO_PICKLIST_ASSIGNED_TO_TOTE'),
        toteId: zod_1.z.string(),
    }),
    zod_1.z.object({
        reason: zod_1.z.literal('FULFILMENT_ORDER_NOT_FOUND'),
        toteId: zod_1.z.string(),
        fulfilmentOrderId: zod_1.z.string().nullish(),
        picklistId: (0, zod_1.string)().nullish(),
    }),
    zod_1.z.object({
        reason: zod_1.z.literal('PICKLIST_NOT_FOUND'),
        picklistId: zod_1.z.string(),
        toteId: zod_1.z.string(),
    }),
    zod_1.z.object({
        reason: zod_1.z.literal('INVALID_CMC_GENESYS_REFERENCE_ID'),
        cmcGenesysReferenceId: zod_1.z.string(),
    }),
    zod_1.z.object({
        reason: zod_1.z.literal('MERCHANT_NOT_FOUND'),
        warehouseId: zod_1.z.string(),
        toteId: zod_1.z.string(),
        fulfilmentOrderId: zod_1.z.string(),
        merchantId: zod_1.z.string(),
    }),
    zod_1.z.object({
        reason: zod_1.z.literal('MERCHANT_WAREHOUSE_CONFIG_NOT_FOUND'),
        warehouseId: zod_1.z.string(),
        merchantId: zod_1.z.string(),
    }),
])
    .and(zod_1.z.object({
    outcome: zod_1.z.literal('CMC_GENESYS_TOTE_VALIDATION_FAILED'),
}));
const validateCmcGenesysToteSuccessOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('SUCCESS'),
    fulfilmentOrder: model_1.fulfilmentOrderSchema,
    toteId: zod_1.z.string(),
    picklistId: zod_1.z.string(),
});
const validateCmcGenesysToteOutcomeSchema = zod_1.z.union([
    validateCmcGenesysToteSuccessOutcomeSchema,
    exports.validateCmcGenesysToteErrorOutcomeSchema,
]);
exports.validateCmcGenesysParcelErrorOutcomeSchema = zod_1.z
    .union([
    zod_1.z.object({
        reason: zod_1.z.literal('NO_WAREHOUSE_FOUND'),
        warehouseId: zod_1.z.string(),
        picklistId: zod_1.z.string(),
    }),
    zod_1.z.object({
        reason: zod_1.z.literal('PICKLIST_NOT_FOUND'),
        warehouseId: zod_1.z.string(),
        picklistId: zod_1.z.string(),
    }),
    zod_1.z.object({
        reason: zod_1.z.literal('FULFILMENT_ORDER_NOT_FOUND'),
        warehouseId: zod_1.z.string(),
        picklistId: zod_1.z.string(),
        fulfilmentOrderId: zod_1.z.string(),
        merchantId: zod_1.z.string(),
    }),
    zod_1.z.object({
        reason: zod_1.z.literal('INVALID_CMC_GENESYS_REFERENCE_ID'),
        cmcGenesysReferenceId: zod_1.z.string(),
    }),
])
    .and(zod_1.z.object({
    outcome: zod_1.z.literal('CMC_GENESYS_PARCEL_VALIDATION_FAILED'),
}));
const validateCmcGenesysParcelSuccessOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('SUCCESS'),
    warehouseId: zod_1.z.string(),
    picklistId: zod_1.z.string(),
    merchantId: zod_1.z.string(),
    fulfilmentOrderId: zod_1.z.string(),
    fulfilmentOrder: model_1.fulfilmentOrderSchema,
});
const validateCmcGenesysParcelOutcomeSchema = zod_1.z.union([
    validateCmcGenesysParcelSuccessOutcomeSchema,
    exports.validateCmcGenesysParcelErrorOutcomeSchema,
]);
const cmcRejectedToteSchema = zod_1.z.object({
    outcome: zod_1.z.literal('CMC_REJECTED_TOTE'),
    reason: zod_1.z.union([
        zod_1.z.literal('NO_READ_ON_VARYTOTE'),
        zod_1.z.literal('OUT_OF_FORMAT'),
        zod_1.z.literal('EMPTY_VARYTOTE'),
        zod_1.z.literal('MISSING_ENQ_MESSAGE'),
        zod_1.z.literal('INCORRECT_ENQ_MESSAGE'),
        zod_1.z.literal('FLAGGED_FOR_REJECTION_IN_ENQ'),
        zod_1.z.literal('BAD_MEASUREMENTS'),
    ]),
    referenceId: zod_1.z.string(),
    fulfilmentOrderId: zod_1.z.string(),
    measurements: http_1.CmcMeasurements,
});
const cmcInvalidMeasurementsProvidedSchema = zod_1.z.object({
    outcome: zod_1.z.literal('CMC_INVALID_MEASUREMENTS_PROVIDED'),
    measurements: http_1.CmcMeasurements,
    referenceId: zod_1.z.string(),
    fulfilmentOrderId: zod_1.z.string(),
    reason: zod_1.z.literal('BAD_MEASUREMENTS'),
});
const cmcGenesysUnmatchedPicklistId = zod_1.z.object({
    outcome: zod_1.z.literal('CMC_GENESYS_UNMATCHED_PICKLIST_ID'),
    referenceId: zod_1.z.string(),
    fulfilmentOrderId: zod_1.z.string(),
    cmcGenesysPicklistId: zod_1.z.string(),
    wmsPicklistId: zod_1.z.string(),
    measurements: http_1.CmcMeasurements,
    reason: zod_1.z.literal('UNMATCHED_PICKLIST_ID'),
});
exports.orderNotEligibleForCmcGenesysSchema = zod_1.z.object({
    outcome: zod_1.z.literal('ORDER_NOT_ELIGIBLE_FOR_CMC_GENESYS'),
    fulfilmentOrderId: zod_1.z.string(),
    toteId: zod_1.z.string(),
    referenceId: zod_1.z.string(),
    picklistId: zod_1.z.string(),
});
exports.orderAlreadyPackedSchema = zod_1.z.object({
    outcome: zod_1.z.literal('ORDER_ALREADY_PACKED'),
    fulfilmentOrderId: zod_1.z.string(),
    toteId: zod_1.z.string(),
    picklistId: zod_1.z.string(),
});
exports.scanCmcGenesysToteErrorOutcomeSchema = zod_1.z.union([
    exports.validateCmcGenesysToteErrorOutcomeSchema,
    exports.orderNotEligibleForCmcGenesysSchema,
    exports.orderAlreadyPackedSchema,
]);
exports.scanCmcGenesysToteSuccessOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('SUCCESS'),
    fulfilmentOrderId: zod_1.z.string(),
    toteId: zod_1.z.string(),
    referenceId: zod_1.z.string(),
});
exports.scanCmcGenesysToteOutcomeSchema = zod_1.z.union([
    exports.scanCmcGenesysToteSuccessOutcomeSchema,
    exports.scanCmcGenesysToteErrorOutcomeSchema,
]);
exports.measureCmcGenesysParcelErrorOutcomeSchema = zod_1.z.union([
    exports.validateCmcGenesysToteErrorOutcomeSchema,
    cmcRejectedToteSchema,
    cmcInvalidMeasurementsProvidedSchema,
    cmcGenesysUnmatchedPicklistId,
]);
exports.measureCmcGenesysParcelSuccessOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('SUCCESS'),
    fulfilmentOrderId: zod_1.z.string(),
    measurements: http_1.CmcMeasurements,
});
exports.measureCmcGenesysParcelOutcomeSchema = zod_1.z.union([
    exports.measureCmcGenesysParcelSuccessOutcomeSchema,
    exports.measureCmcGenesysParcelErrorOutcomeSchema,
]);
const cmcRejectedToteOnExitSchema = zod_1.z.object({
    outcome: zod_1.z.literal('CMC_REJECTED_TOTE_ON_EXIT'),
    reason: zod_1.z.union([
        zod_1.z.literal('VARYTOTE_WITHOUT_TRACKING'),
        zod_1.z.literal('VARYTOTE_NOT_EMPTY'),
        zod_1.z.literal('OUTBOUND_CHECK_INACTIVE'),
        zod_1.z.literal('REJECTED_BY_ACK'),
        zod_1.z.literal('UNKNOWN'),
    ]),
    toteId: zod_1.z.string(),
    referenceId: zod_1.z.string(),
    fulfilmentOrderId: zod_1.z.string(),
});
const exitCmcGenesysToteSuccessOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('SUCCESS'),
    fulfilmentOrderId: zod_1.z.string(),
    toteId: zod_1.z.string(),
});
const exitCmcGenesysToteErrorOutcomeSchema = zod_1.z.union([
    exports.validateCmcGenesysToteErrorOutcomeSchema,
    cmcRejectedToteOnExitSchema,
]);
exports.exitCmcGenesysToteOutcomeSchema = zod_1.z.union([
    exitCmcGenesysToteSuccessOutcomeSchema,
    exitCmcGenesysToteErrorOutcomeSchema,
]);
const cmcRejectedParcelUnderLabellerSchema = zod_1.z.object({
    outcome: zod_1.z.literal('CMC_REJECTED_PARCEL_UNDER_LABELLER'),
    // TODO(WMS-735): address orphaned todo
    // eslint-disable-next-line todo-plz/ticket-ref
    reason: zod_1.z.string(),
    fulfilmentOrderId: zod_1.z.string(),
});
const labelCmcGenesysParcelSuccessOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('SUCCESS'),
    fulfilmentOrderId: zod_1.z.string(),
    zplLabel: zod_1.z.string(),
    labelBarcode: zod_1.z.string().optional(),
    cmcGenesysReferenceId: zod_1.z.string(),
});
const noZplLabelAvailableSchema = zod_1.z.object({
    outcome: zod_1.z.literal('NO_ZPL_LABEL_AVAILABLE'),
    fulfilmentOrderId: zod_1.z.string(),
});
const zplErrorLabelSchema = zod_1.z.object({
    cmcGenesysReferenceId: zod_1.z.string(),
});
const labelCmcGenesysParcelErrorOutcomeSchema = zod_1.z.intersection(zod_1.z.union([
    exports.validateCmcGenesysParcelErrorOutcomeSchema,
    cmcRejectedParcelUnderLabellerSchema,
    noZplLabelAvailableSchema,
]), zplErrorLabelSchema);
exports.labelCmcGenesysParcelOutcomeSchema = zod_1.z.union([
    labelCmcGenesysParcelSuccessOutcomeSchema,
    labelCmcGenesysParcelErrorOutcomeSchema,
]);
const removeCmcGenesysParcelErrorOutcomeSchema = exports.validateCmcGenesysParcelErrorOutcomeSchema;
const removeCmcGenesysParcelSuccessOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('SUCCESS'),
    fulfilmentOrderId: zod_1.z.string(),
});
exports.removeCmcGenesysParcelOutcomeSchema = zod_1.z.union([
    removeCmcGenesysParcelSuccessOutcomeSchema,
    removeCmcGenesysParcelErrorOutcomeSchema,
]);
const cmcRejectedParcelSchema = zod_1.z.object({
    outcome: zod_1.z.literal('CMC_REJECTED_PARCEL'),
    reasonCode: zod_1.z.string(),
    fulfilmentOrderId: zod_1.z.string(),
    referenceId: zod_1.z.string(),
});
const finishCmcGenesysParcelSuccessOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('SUCCESS'),
    fulfilmentOrderId: zod_1.z.string(),
    referenceId: zod_1.z.string(),
});
const finishCmcGenesysParcelErrorOutcomeSchema = zod_1.z.union([
    exports.validateCmcGenesysParcelErrorOutcomeSchema,
    cmcRejectedParcelSchema,
]);
exports.finishCmcGenesysParcelOutcomeSchema = zod_1.z.union([
    finishCmcGenesysParcelSuccessOutcomeSchema,
    finishCmcGenesysParcelErrorOutcomeSchema,
]);
