import styled, {
  css,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';
import { ChipBase } from '../Chip/Chip.elements';

export const chipVariants = [
  'sameday',
  'standard',
  'priority',
  'transitioning',
] as const;

export type ChipVariant = (typeof chipVariants)[number];

export type WrapperProps = {
  $variant: ChipVariant;
};

const colourMap = (
  variant: WrapperProps['$variant']
): FlattenInterpolation<ThemeProps<any>> => {
  switch (variant) {
    case 'sameday':
      return css`
        background-color: ${({ theme }) =>
          theme.palette.category.background.sameday};
      `;
    case 'transitioning':
      return css`
        background-color: ${({ theme }) =>
          theme.palette.category.background.transitioning};
      `;
    case 'priority':
      return css`
        background-color: ${({ theme }) =>
          theme.palette.category.background.priority};
      `;
    case 'standard':
    default:
      return css`
        background-color: ${({ theme }) =>
          theme.palette.category.background.standard};
      `;
  }
};

export const Wrapper = styled(ChipBase)<WrapperProps>`
  ${({ $variant }) => colourMap($variant)};
`;
