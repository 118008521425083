"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssignReleaseChannelToPackingStationResponse = exports.AssignReleaseChannelToPackingStationRequest = exports.ResetPackingStationReleaseChannelResponse = exports.AssignReleaseChannelToPortResponse = exports.AssignReleaseChannelToPortRequest = exports.ResetPortReleaseChannelResponse = exports.GetFeatureFlagsStatusResponse = void 0;
const zod_http_schemas_1 = require("zod-http-schemas");
const base_1 = require("../events/base");
const featureFlags_1 = require("../model/featureFlags");
exports.GetFeatureFlagsStatusResponse = zod_http_schemas_1.z.object({
    warehouses: zod_http_schemas_1.z.array(zod_http_schemas_1.z.object({
        warehouseId: zod_http_schemas_1.z.string().min(1),
        warehouseName: zod_http_schemas_1.z.string().min(1),
        releaseChannel: featureFlags_1.releaseChannel,
        isOverriddenByAnyPort: zod_http_schemas_1.z.boolean(),
        portsStatus: zod_http_schemas_1.z.array(zod_http_schemas_1.z.object({
            portId: base_1.portIdSchema,
            releaseChannel: featureFlags_1.releaseChannel.optional(),
        })),
        isOverriddenByAnyPackingStation: zod_http_schemas_1.z.boolean(),
        packingStationsStatus: zod_http_schemas_1.z.array(zod_http_schemas_1.z.object({
            stationId: zod_http_schemas_1.z.number(),
            releaseChannel: featureFlags_1.releaseChannel.optional(),
        })),
    })),
    features: zod_http_schemas_1.z.array(zod_http_schemas_1.z.object({
        featureName: featureFlags_1.featureKeySchema,
        featureDescription: zod_http_schemas_1.z.string(),
        releaseChannel: featureFlags_1.releaseChannel,
        enabledWarehouses: zod_http_schemas_1.z.array(zod_http_schemas_1.z.object({
            warehouseName: zod_http_schemas_1.z.string(),
            enabledOnAllPorts: zod_http_schemas_1.z.boolean(),
            enabledPortIds: zod_http_schemas_1.z.array(base_1.portIdSchema),
            enabledOnAllPackingStations: zod_http_schemas_1.z.boolean(),
            enabledPackingStationIds: zod_http_schemas_1.z.array(zod_http_schemas_1.z.number()),
        })),
    })),
});
exports.ResetPortReleaseChannelResponse = zod_http_schemas_1.z.union([
    zod_http_schemas_1.z.object({
        outcome: zod_http_schemas_1.z.literal('SUCCESS'),
    }),
    zod_http_schemas_1.z.object({
        outcome: zod_http_schemas_1.z.literal('PORT_ID_IS_NOT_INTEGER_NUMBER'),
        portId: zod_http_schemas_1.z.string(),
    }),
    zod_http_schemas_1.z.object({
        outcome: zod_http_schemas_1.z.union([
            zod_http_schemas_1.z.literal('WAREHOUSE_NOT_FOUND'),
            zod_http_schemas_1.z.literal('PORT_NOT_FOUND'),
            zod_http_schemas_1.z.literal('NO_CURRENT_RELEASE_CHANNEL_ON_PORT'),
        ]),
    }),
]);
exports.AssignReleaseChannelToPortRequest = zod_http_schemas_1.z.object({
    releaseChannel: featureFlags_1.releaseChannel,
});
exports.AssignReleaseChannelToPortResponse = zod_http_schemas_1.z.union([
    zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('SUCCESS') }),
    zod_http_schemas_1.z.object({
        outcome: zod_http_schemas_1.z.literal('PORT_ID_IS_NOT_INTEGER_NUMBER'),
        portId: zod_http_schemas_1.z.string(),
    }),
    zod_http_schemas_1.z.object({
        outcome: zod_http_schemas_1.z.union([
            zod_http_schemas_1.z.literal('WAREHOUSE_NOT_FOUND'),
            zod_http_schemas_1.z.literal('PORT_NOT_FOUND'),
        ]),
    }),
    zod_http_schemas_1.z.object({
        outcome: zod_http_schemas_1.z.literal('SAME_RELEASE_CHANNEL_WITH_WAREHOUSE'),
        releaseChannel: featureFlags_1.releaseChannel,
    }),
]);
exports.ResetPackingStationReleaseChannelResponse = zod_http_schemas_1.z.union([
    zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('SUCCESS') }),
    zod_http_schemas_1.z.object({
        outcome: zod_http_schemas_1.z.literal('PACKING_STATION_ID_IS_NOT_INTEGER_NUMBER'),
        warehouseId: zod_http_schemas_1.z.string(),
        packingStationId: zod_http_schemas_1.z.string(),
    }),
    zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('WAREHOUSE_NOT_FOUND') }),
    zod_http_schemas_1.z.object({
        outcome: zod_http_schemas_1.z.literal('PACKING_STATION_NOT_FOUND'),
        warehouseId: zod_http_schemas_1.z.string(),
    }),
    zod_http_schemas_1.z.object({
        outcome: zod_http_schemas_1.z.literal('NO_CURRENT_RELEASE_CHANNEL_ON_PACKING_STATION'),
        warehouseId: zod_http_schemas_1.z.string(),
        packingStationId: zod_http_schemas_1.z.number(),
    }),
]);
exports.AssignReleaseChannelToPackingStationRequest = zod_http_schemas_1.z.object({
    releaseChannel: featureFlags_1.releaseChannel,
});
exports.AssignReleaseChannelToPackingStationResponse = zod_http_schemas_1.z.union([
    zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('SUCCESS') }),
    zod_http_schemas_1.z.object({
        outcome: zod_http_schemas_1.z.literal('PACKING_STATION_ID_IS_NOT_INTEGER_NUMBER'),
        warehouseId: zod_http_schemas_1.z.string(),
        packingStationId: zod_http_schemas_1.z.string(),
    }),
    zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.literal('WAREHOUSE_NOT_FOUND') }),
    zod_http_schemas_1.z.object({
        outcome: zod_http_schemas_1.z.literal('PACKING_STATION_NOT_FOUND'),
        warehouseId: zod_http_schemas_1.z.string(),
    }),
    zod_http_schemas_1.z.object({
        outcome: zod_http_schemas_1.z.literal('SAME_RELEASE_CHANNEL_WITH_WAREHOUSE'),
        releaseChannel: featureFlags_1.releaseChannel,
        warehouseId: zod_http_schemas_1.z.string(),
        packingStationId: zod_http_schemas_1.z.number(),
    }),
]);
