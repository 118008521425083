"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.merchantEventSchema = void 0;
const zod_1 = require("zod");
const model_1 = require("../model");
const base_1 = require("./base");
const configMerchantStoreSchema = base_1.eventBaseSchema.extend({
    warehouseId: zod_1.z.literal('GLOBAL'),
    type: zod_1.z.literal('MERCHANT_STORE_CONFIGURED'),
    payload: zod_1.z.object({
        merchantId: zod_1.z.string(),
        store: zod_1.z.object({
            id: zod_1.z.string(),
            config: zod_1.z.object({
                isB2B: zod_1.z.boolean(),
            }),
        }),
    }),
});
const merchantAddedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('MERCHANT_ADDED'),
    payload: zod_1.z.object({
        merchant: zod_1.z.object({ id: zod_1.z.string().min(1), name: zod_1.z.string().min(1) }),
    }),
});
const putawayModeSpecifiedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PUTAWAY_MODE_SPECIFIED'),
    payload: zod_1.z.object({
        merchantId: model_1.merchantSchema.shape.id,
        warehouseId: model_1.warehouseSchema.shape.id,
        putawayMode: model_1.putawayModeSchema,
    }),
});
const pickingPrioritySpecifiedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICKING_PRIORITY_SPECIFIED'),
    payload: zod_1.z.object({
        merchantId: model_1.merchantSchema.shape.id,
        pickingPriority: model_1.pickingPrioritySchema,
    }),
});
const merchantConfigurationSavedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('MERCHANT_CONFIGURATION_SAVED'),
    payload: zod_1.z.object({
        merchantId: model_1.merchantSchema.shape.id,
        config: zod_1.z.object({
            putawayMode: model_1.putawayModeSchema.optional(),
            pickingPriority: model_1.pickingPrioritySchema.optional(),
            packingNote: zod_1.z.string().optional(),
            insertRequired: zod_1.z.boolean().optional(),
        }),
    }),
});
const merchantStoreRegisteredSchema = base_1.eventBaseSchema.extend({
    warehouseId: zod_1.z.literal('GLOBAL'),
    type: zod_1.z.literal('MERCHANT_STORE_REGISTERED'),
    payload: zod_1.z.object({
        merchantId: model_1.merchantSchema.shape.id,
        store: zod_1.z.object({
            id: zod_1.z.string(),
            name: zod_1.z.string(),
        }),
    }),
});
exports.merchantEventSchema = zod_1.z.union([
    configMerchantStoreSchema,
    merchantAddedSchema,
    putawayModeSpecifiedSchema,
    pickingPrioritySpecifiedSchema,
    merchantConfigurationSavedSchema,
    merchantStoreRegisteredSchema,
]);
