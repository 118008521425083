"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PicklistEvent = exports.AutostoreHandPicksCompleted = exports.PicklistsUnassignedFromTotes = exports.PicklistsAssignedToTotes = exports.picklistItemsUnfulfillableSchema = exports.picklistCancelledSchema = exports.resolutionPicklistNoLongerNeededSchame = exports.resolutionPicklistCreatedSchema = exports.picklistsAssignedToTaskGroupSchema = exports.picklistDeadlineChangeSchema = exports.picklistCreatedSchema = void 0;
const zod_1 = require("zod");
const base_1 = require("./base");
const fulfilmentOrder_1 = require("./fulfilmentOrder");
const pick_1 = require("./pick");
const shortPickTroubleshoot_1 = require("./shortPickTroubleshoot");
const taskGroup_1 = require("./taskGroup");
const PicklistStatus = zod_1.z.enum([
    'PENDING_TASKGROUP',
    'PENDING_TOTE',
    'PICKING',
    'SHORT_PICKED',
    'COMPLETE',
    'TROUBLESHOOT',
    'CANCELLED',
    'UNFULFILLABLE',
]);
const PicklistLineItem = zod_1.z.object({
    sku: zod_1.z.string(),
    name: zod_1.z.string(),
    barcode: zod_1.z.string().min(1),
    quantityToPick: zod_1.z.number().nonnegative(),
    quantityPicked: zod_1.z.number().nonnegative(),
    quantityMissing: zod_1.z.number().nonnegative(),
    weightGrams: zod_1.z.number().int().positive().optional(),
    options: zod_1.z.array(zod_1.z.string()).optional(),
    imageSrc: zod_1.z.string().min(1).optional(),
    retailUnitId: zod_1.z.string().optional(),
    merchantId: zod_1.z.string().optional(),
});
const Picklist = zod_1.z.object({
    id: zod_1.z.string().min(1),
    merchantId: zod_1.z.string().min(1),
    fulfilmentOrderId: zod_1.z.string().min(1),
    lineItems: zod_1.z.array(PicklistLineItem),
    deadline: zod_1.z.date(),
    status: PicklistStatus,
    isResolutionPicklist: zod_1.z.boolean().optional(),
    isNoLongerNeeded: zod_1.z.boolean().optional(),
    resolvedPicklistId: zod_1.z.string().min(1).optional(),
    toteId: zod_1.z.string().min(1).optional(),
    wasPickedFromAutostoreByHand: zod_1.z.boolean().optional(),
});
exports.picklistCreatedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICKLIST_CREATED'),
    payload: Picklist,
});
exports.picklistDeadlineChangeSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICKLIST_DEADLINE_CHANGED'),
    payload: zod_1.z.object({
        picklists: zod_1.z
            .object({
            id: zod_1.z.string(),
            deadline: zod_1.z.date(),
        })
            .array(),
    }),
});
exports.picklistsAssignedToTaskGroupSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICKLISTS_ASSIGNED_TO_TASK_GROUP'),
    payload: zod_1.z.object({
        picklistIds: zod_1.z.array(zod_1.z.string()),
    }),
});
exports.resolutionPicklistCreatedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('RESOLUTION_PICKLIST_CREATED'),
    payload: Picklist,
});
exports.resolutionPicklistNoLongerNeededSchame = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('RESOLUTION_PICKLIST_NO_LONGER_NEEDED'),
    payload: zod_1.z.object({
        picklistId: zod_1.z.string().min(1),
        originalPicklistId: zod_1.z.string().min(1),
        fulfilmentOrderId: zod_1.z.string().min(1),
    }),
});
exports.picklistCancelledSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICKLIST_CANCELLED'),
    payload: zod_1.z.object({
        id: zod_1.z.string().min(1),
    }),
});
exports.picklistItemsUnfulfillableSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PICKLIST_ITEMS_UNFULFILLABLE'),
    payload: zod_1.z.object({
        picklistId: zod_1.z.string().min(1),
        fulfilmentOrderId: zod_1.z.string().min(1),
        lineItemBarcode: zod_1.z.string().min(1),
        quantityMissing: zod_1.z.number().positive(),
    }),
});
exports.PicklistsAssignedToTotes = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('AUTOSTORE_HAND_PICKING_PICKLISTS_ASSIGNED_TO_TOTES'),
    payload: zod_1.z.object({
        picklistStorageUnitIds: zod_1.z.array(zod_1.z.object({
            storageUnitId: zod_1.z.string(),
            picklistId: zod_1.z.string(),
        })),
    }),
});
exports.PicklistsUnassignedFromTotes = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('AUTOSTORE_HAND_PICKING_PICKLISTS_UNASSIGNED_FROM_TOTES'),
    payload: zod_1.z.object({
        picklistIds: zod_1.z.array(zod_1.z.string()),
    }),
});
exports.AutostoreHandPicksCompleted = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('AUTOSTORE_HAND_PICKS_COMPLETED'),
    payload: zod_1.z.object({
        picks: zod_1.z
            .object({
            fulfilmentOrderId: zod_1.z.string(),
            picklistId: zod_1.z.string(),
            merchantId: zod_1.z.string(),
            toteId: zod_1.z.string(),
            items: zod_1.z
                .object({
                quantity: zod_1.z.number().positive(),
                barcode: zod_1.z.string(),
            })
                .array(),
        })
            .array(),
    }),
});
exports.PicklistEvent = zod_1.z.union([
    exports.picklistCreatedSchema,
    exports.picklistsAssignedToTaskGroupSchema,
    taskGroup_1.picklistsUnassignedFromTaskGroupSchema,
    pick_1.picklistAssignedToToteLocationSchema,
    pick_1.picklistUnassignedFromToteLocationSchema,
    pick_1.pickItemAddedToToteSchema,
    pick_1.pickItemRemovedFromToteSchema,
    pick_1.totesPickingCompletedSchema,
    fulfilmentOrder_1.fulfilmentOrderCancelledSchema,
    fulfilmentOrder_1.fulfilmentOrderRejectedSchema,
    pick_1.shortPickRaisedSchema,
    pick_1.redPaperPlacedInTotesSchema,
    exports.resolutionPicklistCreatedSchema,
    exports.resolutionPicklistNoLongerNeededSchame,
    exports.picklistCancelledSchema,
    exports.picklistItemsUnfulfillableSchema,
    shortPickTroubleshoot_1.shortPickResolvedSchema,
    exports.picklistDeadlineChangeSchema,
    taskGroup_1.TaskGroupCancelled,
    exports.PicklistsAssignedToTotes,
    exports.PicklistsUnassignedFromTotes,
    exports.AutostoreHandPicksCompleted,
]);
