import { ReactElement, useEffect, useState } from 'react';
import { ReactComponent as TroubleshootIcon } from '../../../../assets/img/icons/troubleshoot-icon.svg';
import {
  AlertMessage,
  AlertMessageTypes,
} from '../../../../components/warehouse/AlertMessage';
import { Button } from '../../../../components/warehouse/Button';
import { Container } from '../../../../components/warehouse/Container';
import { Text } from '../../../../components/warehouse/Text';
import { pluralise } from '../../../../utils/pluralise';
import { Loading } from '../Loading';

export type PushBackTotesProps = {
  completedToteCount: number;
  goBack: () => void;
  pushTotes: () => void;
  isUsingConveyor: boolean;
};

type RetryPushToConveyorProps = {
  pushTotes: () => void;
};

const RETRY_BUTTON_WAIT_MS = 10_000;

export const PushBackTotes = ({
  completedToteCount,
  goBack,
  pushTotes,
  isUsingConveyor,
}: PushBackTotesProps): ReactElement => {
  if (isUsingConveyor) {
    return <RetryPushBackTotesToConveyor pushTotes={pushTotes} />;
  }

  return (
    <PushBackTotesManually
      completedToteCount={completedToteCount}
      goBack={goBack}
      pushTotes={pushTotes}
      isUsingConveyor={isUsingConveyor}
    />
  );
};

const RetryPushBackTotesToConveyor = ({
  pushTotes,
}: RetryPushToConveyorProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
      return <RetryPushToConveyorButton pushTotes={pushTotes} />;
    }, RETRY_BUTTON_WAIT_MS);

    return () => {
      clearTimeout(timer);
    };
  });
  if (isLoading) {
    return <Loading message="Pushing back totes" />;
  }
  return <RetryPushToConveyorButton pushTotes={pushTotes} />;
};

const RetryPushToConveyorButton = ({
  pushTotes,
}: RetryPushToConveyorProps): ReactElement => {
  return (
    <Container alignCenter>
      <AlertMessage type={AlertMessageTypes.Warning}>
        Pushing totes failed. Please try again. If it fails again, alert your
        manager.
      </AlertMessage>
      <Button
        variant="primary"
        onClick={pushTotes}
        testId="totes-pushed-button"
      >
        Push totes
      </Button>
    </Container>
  );
};

const PushBackTotesManually = ({
  completedToteCount,
  goBack,
  pushTotes,
}: PushBackTotesProps): ReactElement => {
  return (
    <Container
      buttons={[
        <Button variant="secondary" fullWidth onClick={goBack}>
          Go back
        </Button>,
        <Button variant="warning" fullWidth icon={<TroubleshootIcon />}>
          Troubleshoot
        </Button>,
      ]}
      padding="none"
      alignCenter
    >
      <Text variant="body1" weight="medium">
        Push back the {pluralise('completed tote', completedToteCount)}
      </Text>
      <Button
        variant="primary"
        onClick={pushTotes}
        testId="totes-pushed-button"
      >
        Totes pushed
      </Button>
    </Container>
  );
};
