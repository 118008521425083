import { ReactNode } from 'react';
import { ReactComponent as ArrowsSyncIcon } from '../../../assets/img/icons/arrows-sync.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/img/icons/calendar.svg';
import { ReactComponent as ImportantIcon } from '../../../assets/img/icons/important-diamond.svg';
import { ChipVariant, Wrapper } from './PortCategoryChip.elements';

export type PortCategoryChipProps = {
  children: ReactNode;
  variant?: ChipVariant;
};

const getVariantIcon = (variant: ChipVariant): ReactNode => {
  switch (variant) {
    case 'sameday':
      return <CalendarIcon />;
    case 'transitioning':
      return <ArrowsSyncIcon />;
    case 'priority':
      return <ImportantIcon />;
    case 'standard':
    default:
      return null;
  }
};

export function PortCategoryChip({
  children,
  variant = 'standard',
}: PortCategoryChipProps) {
  const icon = getVariantIcon(variant);
  return (
    <Wrapper $variant={variant}>
      {icon}
      {children}
    </Wrapper>
  );
}
