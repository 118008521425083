"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.spectrumWcsApiSchema = void 0;
const zod_http_schemas_1 = require("zod-http-schemas");
const commandBase = zod_http_schemas_1.z.object({
    warehouseId: zod_http_schemas_1.z.string().optional(),
    networkAddress: zod_http_schemas_1.z.string().optional(),
    type: zod_http_schemas_1.z.string(),
    payload: zod_http_schemas_1.z.unknown(),
});
exports.spectrumWcsApiSchema = (0, zod_http_schemas_1.createHttpSchema)({
    'POST /spectrum-wcs/command': {
        requestBody: zod_http_schemas_1.z.union([
            commandBase.extend({
                type: zod_http_schemas_1.z.literal('GET_BAY_BY_ID'),
                payload: zod_http_schemas_1.z.object({
                    bayId: zod_http_schemas_1.z.string(),
                }),
            }),
            commandBase.extend({
                type: zod_http_schemas_1.z.literal('GET_ALL_BAYS'),
            }),
            commandBase.extend({
                type: zod_http_schemas_1.z.literal('OPEN_BAY'),
                payload: zod_http_schemas_1.z.object({
                    bayId: zod_http_schemas_1.z.string(),
                }),
            }),
            commandBase.extend({
                type: zod_http_schemas_1.z.literal('CLOSE_BAY'),
                payload: zod_http_schemas_1.z.object({
                    bayId: zod_http_schemas_1.z.string(),
                }),
            }),
            commandBase.extend({
                type: zod_http_schemas_1.z.literal('GET_ALL_TOTES'),
            }),
            commandBase.extend({
                type: zod_http_schemas_1.z.literal('GET_TOTE_STATE'),
                payload: zod_http_schemas_1.z.object({
                    toteId: zod_http_schemas_1.z.string(),
                }),
            }),
            commandBase.extend({
                type: zod_http_schemas_1.z.literal('START_TOTE_JOB'),
                payload: zod_http_schemas_1.z.object({
                    toteId: zod_http_schemas_1.z.string(),
                    jobId: zod_http_schemas_1.z.string(),
                    zoneRequests: zod_http_schemas_1.z.record(zod_http_schemas_1.z.boolean()),
                }),
            }),
            commandBase.extend({
                type: zod_http_schemas_1.z.literal('UPDATE_TOTE_JOB'),
                payload: zod_http_schemas_1.z.object({
                    toteId: zod_http_schemas_1.z.string(),
                    jobId: zod_http_schemas_1.z.string(),
                    zoneRequests: zod_http_schemas_1.z.record(zod_http_schemas_1.z.boolean()),
                }),
            }),
            commandBase.extend({
                type: zod_http_schemas_1.z.literal('COMPLETE_TOTE_ZONE'),
                payload: zod_http_schemas_1.z.object({
                    toteId: zod_http_schemas_1.z.string(),
                    jobId: zod_http_schemas_1.z.string(),
                    zoneId: zod_http_schemas_1.z.string(),
                }),
            }),
            commandBase.extend({
                type: zod_http_schemas_1.z.literal('COMPLETE_TOTE_JOB'),
                payload: zod_http_schemas_1.z.object({
                    toteId: zod_http_schemas_1.z.string(),
                    jobId: zod_http_schemas_1.z.string(),
                }),
            }),
            commandBase.extend({
                type: zod_http_schemas_1.z.literal('COMPLETE_TOTE_JOB_FORCE'),
                payload: zod_http_schemas_1.z.object({
                    toteId: zod_http_schemas_1.z.string(),
                }),
            }),
            commandBase.extend({
                type: zod_http_schemas_1.z.literal('SET_BAY_TASK_TYPE'),
                payload: zod_http_schemas_1.z.object({
                    bayId: zod_http_schemas_1.z.string(),
                    taskType: zod_http_schemas_1.z.union([
                        zod_http_schemas_1.z.literal(0),
                        zod_http_schemas_1.z.literal(1),
                        zod_http_schemas_1.z.literal(2),
                        zod_http_schemas_1.z.literal(4),
                        zod_http_schemas_1.z.literal(8),
                    ]),
                }),
            }),
        ]),
        responseBody: zod_http_schemas_1.z.object({ outcome: zod_http_schemas_1.z.string(), data: zod_http_schemas_1.z.any() }),
    },
});
