"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PortEvent = exports.closePickPortFailed = exports.releaseChannelResetOnPortSchema = exports.releaseChannelAssignedToPortSchema = exports.autostorePortOpenedSchema = exports.autostorePortClosedSchema = exports.stationUnlockedSchema = exports.stationLockedSchema = exports.retryCloseBinSucceededSchema = exports.closeBinFailedSchema = exports.AutostorePortsAdded = exports.AutostorePortsCreated = exports.portConfigurationChangedSchema = exports.BinRetrievalTimedOut = exports.binArrivedSchema = exports.binRequestedSchema = void 0;
const zod_1 = require("zod");
const model_1 = require("../model");
const featureFlags_1 = require("../model/featureFlags");
const base_1 = require("./base");
exports.binRequestedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('BIN_REQUESTED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        autostoreBinId: base_1.autostoreBinIdSchema,
        binId: zod_1.z.string().min(1),
        autostoreTaskId: zod_1.z.number().optional(),
    }),
});
exports.binArrivedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('BIN_ARRIVED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        binRetrievalDurationMs: zod_1.z.number(),
        taskId: zod_1.z.number().int().nonnegative().optional(),
        // TODO(WMS-875): remove .optional() for binId and autostoreBinId
        // when backfilling the existing events
        autostoreBinId: zod_1.z.number().int().gte(10000).optional(),
        binId: zod_1.z.string().min(1).optional(),
    }),
});
exports.BinRetrievalTimedOut = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('BIN_RETRIEVAL_TIMED_OUT'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        binRetrievalDurationMs: zod_1.z.number(),
    }),
});
exports.portConfigurationChangedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PORT_CONFIGURATION_CHANGED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        type: model_1.portTypeSchema,
        toteLocationCount: zod_1.z.number().int().positive().optional(),
    }),
});
exports.AutostorePortsCreated = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('AUTOSTORE_PORTS_CREATED'),
    payload: zod_1.z.object({
        portsCreated: zod_1.z.array(zod_1.z.number()).optional(),
    }),
});
exports.AutostorePortsAdded = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('AUTOSTORE_PORTS_ADDED'),
    payload: zod_1.z.object({
        portIds: zod_1.z.array(zod_1.z.number()),
    }),
});
exports.closeBinFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CLOSE_BIN_FAILED'),
    payload: zod_1.z.object({
        autostoreBinId: zod_1.z.number(),
        binId: zod_1.z.string().min(1),
        portId: base_1.portIdSchema,
    }),
});
exports.retryCloseBinSucceededSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('RETRY_CLOSE_BIN_SUCCEEDED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
    }),
});
exports.stationLockedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('STATION_LOCKED'),
    payload: zod_1.z.object({
        type: model_1.portTypeSchema,
        portId: base_1.portIdSchema,
        reason: model_1.LockReason.optional(),
        picklistIds: zod_1.z.array(base_1.StringId).optional(),
    }),
});
exports.stationUnlockedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('STATION_UNLOCKED'),
    payload: zod_1.z.object({
        type: model_1.portTypeSchema,
        portId: base_1.portIdSchema,
    }),
});
exports.autostorePortClosedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('AUTOSTORE_PORT_CLOSED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
    }),
});
exports.autostorePortOpenedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('AUTOSTORE_PORT_OPENED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        categories: model_1.PickPortSelectCategories,
        hasChangedCategories: zod_1.z.boolean().optional(),
    }),
});
exports.releaseChannelAssignedToPortSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('RELEASE_CHANNEL_ASSIGNED_TO_PORT'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        releaseChannel: featureFlags_1.releaseChannel,
    }),
});
exports.releaseChannelResetOnPortSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('RELEASE_CHANNEL_RESET_ON_PORT'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
    }),
});
exports.closePickPortFailed = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CLOSE_PICK_PORT_FAILED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        reason: zod_1.z.union([
            zod_1.z.literal('PORT_NOT_FOUND'),
            zod_1.z.literal('INVALID_PORT_TYPE'),
            zod_1.z.literal('INVALID_PORT_STATUS'),
        ]),
    }),
});
exports.PortEvent = zod_1.z.union([
    exports.portConfigurationChangedSchema,
    exports.AutostorePortsCreated,
    exports.retryCloseBinSucceededSchema,
    exports.closeBinFailedSchema,
    exports.stationLockedSchema,
    exports.stationUnlockedSchema,
    exports.autostorePortClosedSchema,
    exports.autostorePortOpenedSchema,
    exports.AutostorePortsAdded,
    exports.releaseChannelAssignedToPortSchema,
    exports.releaseChannelResetOnPortSchema,
    exports.closePickPortFailed,
]);
