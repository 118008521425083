export const PORT_CATEGORY = {
  STANDARD: 1,
  SAME_DAY: 2,
  PRIORITY: 3,
} as const;

type Values<T> = T[keyof T];
export type PortCategoryEnumValueType = Values<typeof PORT_CATEGORY>;

export const PORT_CATEGORY_LABEL = {
  [PORT_CATEGORY.STANDARD]: 'Standard',
  [PORT_CATEGORY.SAME_DAY]: 'Same Day',
  [PORT_CATEGORY.PRIORITY]: 'Priority',
} as const;
